<template>
  <div>
    <vehicule-form type-ajout="reprise" v-if="vehicule" :disabled-fields="disabledFields" :initial-vehicule="vehicule" @created="vehiculeCreated"/>


  </div>
</template>

<script>
import VehiculeForm from "@/components/VehiculeForm";

export default {
  components: {
    VehiculeForm
  },
  data() {
    return {
      vehicule : false,
      disabledFields: [],
    }
  },
  created() {
    console.log(this.$store.state.initialConfigModule.initialState.userData.userData.site);
    let concession = this.$store.state.initialConfigModule.initialState.userData.userData.concession;
    let site = this.$store.state.initialConfigModule.initialState.userData.userData.site;
    this.disabledFields = ['concession', 'site'];
    
    if(this.$can('voir-reprise-global', 'reprise') || this.$can('voir-reprise-concession', 'reprise')){
      this.disabledFields = [];
    }
    if((this.$can('voir-reprise-site', 'reprise'))){
      this.disabledFields = ['concession'];
    }
    
    this.vehicule = {
      proprietaire : {},
      statut_vente : { id: 1, libelle: "Estimation de reprise", hidden : true },
      site : site,
      concession : concession,
      zone : this.$store.state.initialConfigModule.initialState.userData.userData.site,
      vendeur : {
        id : this.$store.state.initialConfigModule.initialState.userData.userData.id,
        full_name : this.$store.state.initialConfigModule.initialState.userData.userData.fullName,
      }
    };
  },
  methods: {
    vehiculeCreated(vehicule){
      this.$router.push({
        name : 'reprise-view',
        params : {
          id : vehicule.id
        }
      });
    },
   
  },
}
</script>

<style lang="scss">
 
</style>